const ChiffresCles = (chiffresCles) => {

  return (
    <div>
      <p>{JSON.stringify(chiffresCles)}</p>
      <hr class="h-px my-6 bg-gray-200 border-0 dark:bg-gray-700" />
    </div>
  );
};

export default ChiffresCles;
