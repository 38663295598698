import Card from "../card/card.component";
import "./card-list.styles.css";

const CardList = ({ companies }) => (
  <div className="card-list" key="cardlist">
    {companies.map((company) => {
      return (
        <Card
          company={company}
          className="card-container"
          key={company.siren + company.siret}
        />
      );
    })}
  </div>
);

export default CardList;
