import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layout";
import SearchPage from "./pages/SearchPage.js";
import Results from "./pages/ResultsSirenPage.js";

function MyRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Layout children={<SearchPage />} />} />
        <Route path="/results" element={<Layout children={<Results />} />} />
        <Route path="*" element={<Layout children={<SearchPage />} />} />
      </Routes>
    </BrowserRouter>
  );
}

export default MyRoutes;
