import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import CardList from "../components/card-list/card-list.component";
import SearchBox from "../components/search-box/search-box.component.jsx";
import "../App.css";

const ResultsSiren = (props) => {
  const navigate = useNavigate();

  const location = useLocation();
  const propsData = location.state;
  const { searchQuery } = propsData;
  const [searchField, setSearchField] = useState("");
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState([]);

  useEffect(() => {
    async function fetchCompanies(companyName) {
      const requestOptions = {
        method: "GET",
      };
      let response = await fetch(
        "/api/companies?searchQuery=" + companyName,
        requestOptions
      );
      let responseJSON = await response.json();

      setCompanies(responseJSON.etablissements);
    }


    fetchCompanies(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    const newFilteredCompanies = companies.filter((company) => {
      let nom =
        (company.etablissement.denominationUsuelle || "") +
        " " +
        (company.uniteLegale.denomination || "") +
        " " +
        (company.uniteLegale.identite || "");
      if (nom) return nom.toLocaleLowerCase().includes(searchField);
      else return "";
    });
    setFilteredCompanies(newFilteredCompanies);
  }, [companies, searchField]);

  const onSearchChange = (event) => {
    const searchFieldString = event.target.value.toLocaleLowerCase();
    setSearchField(searchFieldString);
  };

  return (
    <div className="App">
      <button
        class="focus:outline-none text-white bg-orange-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
        onClick={() => navigate("/")}
      >
        Nouvelle recherche
      </button>
      <br />
      <h2 class="text-4xl font-extrabold dark:text-white">
        Résultats pour la recherche {searchQuery}
      </h2>
      <br />
      <SearchBox
        onSearchHandler={onSearchChange}
        // className="company-search-box"
        placeholder="Filtrer"
        className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
      />
      <br />
      <CardList companies={filteredCompanies} />
    </div>
  );
};

export default ResultsSiren;
