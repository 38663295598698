import "./field.styles.css";

const Field = ({ title, text, className }) => {
    if (text) {
        return (
          <div className={className}>
            <table>
              <td>
                <tr className="filedTitle">{title} : ‎</tr>
              </td>
              <td>
                <tr>{text}</tr>
              </td>
            </table>
          </div>
        );
    }
    return "";
};

export default Field;
