import React, { useState } from "react";
import Modal from "react-modal";
import ChiffresCles from "./chiffresCles.component";

// Le style pour la pop-up (modal)
const customModalStyle = {
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    maxWidth: "400px",
  },
};

const MyPopupChiffresCles = (props) => {
  // State pour gérer l'affichage de la pop-up
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [chiffresCles, setChiffresCles] = useState([]);
  const { siren } = props;

  async function fetchChiffresCles() {
    const requestOptions = {
      method: "GET",
    };
    let response = await fetch(
      "/api/chiffresCles?siren=" + siren,
      requestOptions
    );
    let responseJSON = await response.json();
    setChiffresCles(responseJSON);
  }

  // Fonction pour ouvrir la pop-up
  const openModal = async () => {
    await fetchChiffresCles();
    setIsModalOpen(true);
  };

  // Fonction pour fermer la pop-up
  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <button
        class="focus:outline-none text-white bg-orange-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
        onClick={openModal}
      >
        Voir les chiffres clés
      </button>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={customModalStyle}
        contentLabel="ChiffresCles"
      >
        <h3 class="text-3xl font-extrabold dark:text-white">Chiffres Clés</h3>
        <hr class="h-px my-8 bg-gray-200 border-0 dark:bg-gray-700" />
        {chiffresCles.map((object) => (
          <ChiffresCles chiffresCles={object} />
        ))}

        <button
          class="focus:outline-none text-white bg-orange-400 hover:bg-yellow-500 focus:ring-4 focus:ring-yellow-300 font-medium rounded-lg text-sm px-5 py-2.5 mr-2 mb-2 dark:focus:ring-yellow-900"
          onClick={closeModal}
        >
          Fermer
        </button>
      </Modal>
    </div>
  );
};

export default MyPopupChiffresCles;
