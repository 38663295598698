const CCN = (ccn) => {
  const { idcc, libelleidcc, libelleIdcc } = ccn.ccn;

  return (
    <div>
      <p>idcc : {idcc}</p>
      <p>{libelleidcc}{libelleIdcc}</p>
      <hr class="h-px my-6 bg-gray-200 border-0 dark:bg-gray-700" />
    </div>
  );
};

export default CCN;
