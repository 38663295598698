const Encours = (encours) => {

  return (
    <div>
      <p>{JSON.stringify(encours)}</p>
      <hr class="h-px my-6 bg-gray-200 border-0 dark:bg-gray-700" />
    </div>
  );
};

export default Encours;
