import React from 'react';
import styled from 'styled-components';

const StyledLayout = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  align-items: center;
`;

const StyledLayoutBody = styled.div`
  margin: 50px auto 100px auto;
  width: 80%;
  display: block;
  flex-direction: column;
  align-items: center;
`; 

const StyledLogo = styled.img`
  margin-top: 32px;
  padding: 16px;
  height: 150px;
`;

const Layout = ({
  children
}) => (
  <StyledLayout>
    <StyledLogo src="/images/logo_april.jpg"/>
    <StyledLayoutBody>
      {children}
    </StyledLayoutBody>
  </StyledLayout>
);

export default Layout;